import { createAction } from '../common/actionUtils';
import {
  SetPlatformParamsPayload,
  SetPlatformParamsSettingsPayload,
  SetPlatformParamsLabelsStylesPayload,
} from './platformParams.actions.types';

export const setPlatformParams = createAction<SetPlatformParamsPayload>('SET_PLATFORM_PARAMS');
export const setPlatformParamsSettings = createAction<SetPlatformParamsSettingsPayload>('SET_PLATFORM_PARAMS_SETTINGS');
export const setPlatformParamsLabelsStyles = createAction<SetPlatformParamsLabelsStylesPayload>(
  'SET_PLATFORM_PARAMS_STYLES',
);

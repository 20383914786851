import { setPlatformParams, setPlatformParamsSettings, setPlatformParamsLabelsStyles } from './platformParams.actions';
import {
  SetPlatformParamsPayload,
  SetPlatformParamsSettingsPayload,
  SetPlatformParamsLabelsStylesPayload,
  SetSignedInstancePayload,
} from './platformParams.actions.types';
import { createReducer } from '../common/reducerUtils';
import { EMPTY_INSTANCE } from '../../core/logic/instanceLogic';
import { PlatformParams, AlignmentOptions } from '@wix/restaurants-client-logic';
import { setSignedInstance } from '../session/session.actions';
import { SIDE_BY_SIDE } from '../../components/MainPage/SharedSettings/Constants';

const initialState: PlatformParams = {
  compId: '',
  isMobile: false,
  isRTL: false,
  instance: EMPTY_INSTANCE,
  settings: {
    layout: SIDE_BY_SIDE,
    showDishImages: true,
    showSectionImages: true,
    showDescriptions: true,
    showMenuDescription: true,
    showSectionDescription: true,
    showLabels: true,
    hasMembersAreaIntegration: false,
    dishCardAlignment: AlignmentOptions.EMPTY,
    menuHeaderAlignment: AlignmentOptions.EMPTY,
  },
  signedInstance: '',
  viewMode: '',
  labelsSettings: {
    colorTypeId: 0, // classic
    iconTypeId: 3, // outline
    iconPrimaryColor: '',
    iconSecondaryColor: '',
    iconCustomPrimaryColor: '',
    iconCustomSecondaryColor: '',
  },
};

const reducer = createReducer<PlatformParams>(initialState, {
  [setPlatformParams.toString()]: (state, payload: SetPlatformParamsPayload) => {
    return {
      ...state,
      ...payload.platformParams,
    };
  },
  [setPlatformParamsSettings.toString()]: (state, payload: SetPlatformParamsSettingsPayload) => {
    return {
      ...state,
      settings: payload.settings,
    };
  },
  [setPlatformParamsLabelsStyles.toString()]: (state, payload: SetPlatformParamsLabelsStylesPayload) => {
    return {
      ...state,
      labelsSettings: payload.labelsSettings,
    };
  },
  [setSignedInstance.toString()]: (state, payload: SetSignedInstancePayload) => {
    return {
      ...state,
      signedInstance: payload.signedInstance,
    };
  },
});

export default reducer;
